import { Formik, Field, ErrorMessage } from 'formik';
import { useState } from 'react';

import { IGeneratePDF } from '../../types/generatepdf';
import { useGeneratePDF } from '../../hooks/useGeneratePDF';
import { getUserBasicData } from '../../tools/utils';
import { generatepdf } from '../../schemas/generatepdf';
import moment from 'moment';
import { Required } from '../../components/Required/Required';
import { BlockUI, Loader } from '../../components/Loader';
import { GeneratePDFAccordion } from '../../components/Accordion/GeneratePDFAccordion';

import { useCredentials } from '../../hooks/useCredentials';



export const GeneratePDF = () => {
    const generatePDFMutation = useGeneratePDF();
    const myCredentialsQuery = useCredentials();
    const userCredentials = myCredentialsQuery?.data?.data?.Data;
    const proctorCredential = userCredentials?.MyCredentials.find(
        x => x.EarnedCredential.Name === 'Proctor-Practical' && (x.EarnedCredential.StatusReason === 'Approved' || x.EarnedCredential.StatusReason==="PendingRenewal")
    );
    const data = getUserBasicData();
    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [documentType, setDocumentType] = useState('');

    const initialValues = {
        qty: 'default',
        examtype: 'default',
    };

    const initialValuesSGP = {
        qty: '1',
        examtype: '1',
    };

    const handleTermsAndConditionsChange = (event: any) => {
        setTermsAndConditions(event.target.checked);
    };

    const handleDocumentType = (type:string) => {
        setDocumentType(type);
    };

    const generateType = [
        {
            name: 'Generate Score Sheet PDFs',
            id: 1,
            type: 'scoresheet',
        },
        {
            name: 'Generate Signalperson Exam PDF',
            id: 2,
            type: 'singleperson',
        },
        {
            name: 'Generate Signalperson CRG PDF',
            id: 3,
            type: 'CRG',
        },
    ];

    // const isValidated = !termsAndConditions;

    return (
        <div id="main-content">
        <div className="cco_content_area cco_content_area_0">
        <article className="react-container" >
            <div className="cco_section cco_section_0">
                <div className="cco_row">
                    <div className="cco_col">
                        <div className="cco_row cco_row_8">
                            <div className="cco_col">
                                <div className="cco_text cco_text_11">
                                    <div className="cco_text_inner">
                                        <strong>
                                            <a href="/testing-personnel">Testing Personnel</a>
                                        </strong>{' '}
                                        &gt; {'Generate PDFs'}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <>

                        <div className="cco_row" style={{ marginBottom: '20px' }}>
                                <div className="cco_col">
                                    {myCredentialsQuery.isLoading ? (
                                        <Loader />
                                    ) : myCredentialsQuery.error ? (
                                        <p>{myCredentialsQuery.error.message}</p>
                                    ) : !proctorCredential ? (
                                        <div className="cco_text cco_text_12">
                                            <div className="cco_text_inner">
                                                <p>
                                                    Our records indicate that you are not authorized to access this
                                                    page. Please contact CCO if you have any questions.{' '}
                                                </p>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {proctorCredential && (
                                <>
                                    {documentType === '' ? (
                                        <div className="cco_row cco_row_3">
                                            <div className="cco_col">
                                                {generateType.length > 0 ? (
                                                    <div className="cco_group cco_group_1">
                                                        {generateType.map((item, index: number) => (
                                                            <div className="cco_group_item" key={index}>
                                                                <GeneratePDFAccordion
                                                                    name={item.name}
                                                                    setDocumentType={handleDocumentType}
                                                                    type={item.type}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <p>No Credentials available</p>
                                                )}
                                            </div>
                                        </div>
                                    ) : documentType === 'scoresheet' ? (
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={initialValues}
                                            validationSchema={generatepdf}
                                            onSubmit={(values, { setSubmitting }) => {
                                                setisLoading(true);
                                                const GenerateObj: IGeneratePDF = {
                                                    firstname: data.First,
                                                    lastname: data.Last,
                                                    // accreditationnumber: 34523,
                                                    examtype: values.examtype,
                                                    qty: values.qty,
                                                    ccoid: data.CandidateId,
                                                };
                                                console.log(GenerateObj);
                                                console.log(values);
                                                generatePDFMutation
                                                    .mutateAsync({
                                                        body: GenerateObj,
                                                        type: documentType,
                                                    })
                                                    .then(async res => {
                                                        if (res.success && res.status === 200) {
                                                            const blob = await res.data.blob();
                                                            const todayDate = moment().format('MMDDYY');

                                                            // Create a download link
                                                            const downloadLink = document.createElement('a');
                                                            downloadLink.href = URL.createObjectURL(blob);
                                                            downloadLink.download = `CCO_${values.examtype}_${todayDate}.pdf`;
                                                            // Append the link to the body
                                                            document.body.appendChild(downloadLink);

                                                            // Trigger a click on the link to start the download
                                                            downloadLink.click();

                                                            // Remove the link from the body
                                                            document.body.removeChild(downloadLink);
                                                            setSubmitting(false);

                                                            setisLoading(false);

                                                            setTimeout(() => {});
                                                        }

                                                        // if (res?.data?.ApiCode === 200 && res.success === true) {

                                                        // }
                                                    })
                                                    .finally(() => {
                                                        setSubmitting(false);
                                                        setisLoading(false);
                                                    })
                                                    .catch(err => {
                                                        console.log(err);
                                                        setSubmitting(false);
                                                        setisLoading(false);
                                                    });
                                            }}
                                        >
                                            {({ isSubmitting, handleSubmit, handleReset, isValid, dirty }) => (
                                                <form
                                                    onReset={handleReset}
                                                    onSubmit={e => {
                                                        if (isSubmitting) {
                                                            e.preventDefault();
                                                        } else {
                                                            handleSubmit(e);
                                                        }
                                                    }}
                                                    className="cco_form"
                                                >
                                                    <div className="cco_row">
                                                        <div className="cco_col">
                                                            <>
                                                                <div className="cco_row cco_row_7">
                                                                    <div className="cco_col cco_col_6">
                                                                        <div className="cco_text cco_text_10">
                                                                            <div className="cco_text_inner">
                                                                                <h2 className="cco_cco_heading">
                                                                                    PRACTICAL EXAM SCORE SHEET DOWNLOAD
                                                                                </h2>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/* <div className="cco_col cco_col_7">
                                            <div className="cco_btn cco_btn_3">
                                                <a href={'#'} onClick={handler}>Back to Test Site</a>
                                            </div>
                                        </div> */}
                                                                </div>

                                                                <div className="cco_row">
                                                                    <div className="cco_col_12">
                                                                        <h6>
                                                                            By downloading score sheets, you agree to
                                                                            the following terms and conditions:
                                                                        </h6>

                                                                        <ul style={{ listStyleType: 'disc' }}>
                                                                            <li>
                                                                            ▪️ You are a credentialed Practical Exam
                                                                                Proctor with at least one proctor
                                                                                credential designation.
                                                                            </li>

                                                                            <li>
                                                                            ▪️ You may only download score sheets for
                                                                                credential designations that you have.
                                                                            </li>

                                                                            <li>
                                                                            ▪️ You are approved to administer CCO
                                                                                practical exams.
                                                                            </li>

                                                                            <li>
                                                                            ▪️ Each score sheet is serialized and can
                                                                                be used only once. You may print them
                                                                                once, and no copies can be made after
                                                                                printing.
                                                                            </li>

                                                                            <li>
                                                                            ▪️ Each score sheet has an expiration date
                                                                                (top right corner). The score sheet may
                                                                                not be used after its expiration date.
                                                                            </li>

                                                                            <li>
                                                                            ▪️  Unused (expired) score sheets must be
                                                                                destroyed. PDF files must be deleted
                                                                                after use.
                                                                            </li>

                                                                            <li>
                                                                            ▪️ You understand that the score sheets are
                                                                                protected by federal copyright law.
                                                                            </li>

                                                                            <li>
                                                                            ▪️ You will not share this link or any
                                                                                score sheets with anyone. Only you are
                                                                                allowed to handle or submit practical
                                                                                exam candidate score sheets.
                                                                            </li>

                                                                            <li>
                                                                            ▪️ You will keep all score sheets in a
                                                                                secured location.
                                                                            </li>

                                                                            <li>
                                                                            ▪️ You will delete all downloads or destroy
                                                                                any hard copies of score sheets if
                                                                                required to do so by CCO.
                                                                            </li>

                                                                            <li>
                                                                            ▪️ You understand the consequences as
                                                                                outlined in the Practical Exam Proctor
                                                                                Handbook on the custody, care, and
                                                                                control of the score sheets.
                                                                            </li>

                                                                            <li>
                                                                            ▪️ You are not acting to circumvent the
                                                                                sanction of another Practical Exam
                                                                                Proctor.
                                                                            </li>

                                                                            <li>
                                                                            ▪️  You agree to administer the exams
                                                                                according to CCO policies and
                                                                                procedures.
                                                                            </li>

                                                                            <p className="ccolinks pt-3">
                                                                                Note: It is highly recommended that all
                                                                                score sheets be printed double-sided.
                                                                            </p>
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                                <div className="cco_form_row">
                                                                    <div className="cco_form_col_1">
                                                                        <div className="cco_form">
                                                                            <div className="cco_form_row">
                                                                                <div className="cco_form_col">
                                                                                    <ul className="cco_menu cco_menu_8 ">
                                                                                        <li
                                                                                            className={
                                                                                                'cco_menu_item cco_menu_item_checkbox'
                                                                                            }
                                                                                        >
                                                                                            <label
                                                                                                className={
                                                                                                    'cco_form_checkbox cco_form_checkbox_0'
                                                                                                }
                                                                                            >
                                                                                                <input
                                                                                                    name="termsAndConditions"
                                                                                                    id="ccoTermsAndConditions"
                                                                                                    type="checkbox"
                                                                                                    className={
                                                                                                        'cco_form_checkbox_input'
                                                                                                    }
                                                                                                    value="termsAndConditions"
                                                                                                    onChange={
                                                                                                        handleTermsAndConditionsChange
                                                                                                    }
                                                                                                />
                                                                                                <span
                                                                                                    className={
                                                                                                        'cco_form_checkbox_checkmark'
                                                                                                    }
                                                                                                    style={{
                                                                                                        border: termsAndConditions
                                                                                                            ? '2px solid #c67b1d'
                                                                                                            : '1px solid #c67b1d',
                                                                                                    }}
                                                                                                ></span>
                                                                                                <p
                                                                                                    style={{
                                                                                                        fontSize:
                                                                                                            '12px',
                                                                                                    }}
                                                                                                >
                                                                                                    I acknowledge that I
                                                                                                    have read and
                                                                                                    understood the above
                                                                                                    policies and
                                                                                                    procedures in their
                                                                                                    entirety and agree
                                                                                                    to abide by them.
                                                                                                    (This box must be
                                                                                                    checked to place an
                                                                                                    order.)
                                                                                                </p>
                                                                                            </label>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                            <ul className="cco_form_fieldset">
                                                                                <li className="cco_form_fieldset_item cco_form_fieldset_item_large">
                                                                                    <label htmlFor="ccoisBoomTruck">
                                                                                        Score Sheet Type <Required />
                                                                                    </label>
                                                                                    <Field
                                                                                        as="select"
                                                                                        name="examtype"
                                                                                        id="ccoexamtype"
                                                                                    >
                                                                                        <option
                                                                                            value="default"
                                                                                            disabled={true}
                                                                                        >
                                                                                            Select an option
                                                                                        </option>

                                                                                        <option value="AMP">
                                                                                            ANCHOR/MICROPILE DRILL RIG
                                                                                        </option>
                                                                                        <option value="ABC">
                                                                                            ARTICULATING BOOM CRANE
                                                                                        </option>
                                                                                        <option value="ABL">
                                                                                            ARTICULATING BOOM LOADER
                                                                                        </option>
                                                                                        <option value="CPO1">
                                                                                            CONCRETE PUMP OPERATOR –
                                                                                            CLASS 1
                                                                                        </option>
                                                                                        <option value="CPO2">
                                                                                            CONCRETE PUMP OPERATOR –
                                                                                            CLASS 2
                                                                                        </option>
                                                                                        <option value="DPD">
                                                                                            DEDICATED PILE DRIVER
                                                                                        </option>
                                                                                        <option value="DDO">
                                                                                            DIGGER DERRICK
                                                                                        </option>
                                                                                        <option value="FDR">
                                                                                            FOUNDATION DRILL RIG
                                                                                        </option>
                                                                                        <option value="LAT">
                                                                                            MCO-LATTICE BOOM CRANE (LAT)
                                                                                        </option>
                                                                                        <option value="TLL">
                                                                                            MCO-TELESCOPIC BOOM-SWING
                                                                                            CAB (TLL)
                                                                                        </option>
                                                                                        <option value="TSS">
                                                                                            MCO-TELESCOPIC BOOM-FIXED
                                                                                            CAB (TSS)
                                                                                        </option>
                                                                                        <option value="OVR">
                                                                                            OVERHEAD CRANE OPERATOR
                                                                                        </option>
                                                                                        <option value="RIG1">
                                                                                            RIGGER LEVEL I
                                                                                        </option>
                                                                                        <option value="RIG2">
                                                                                            RIGGER LEVEL II
                                                                                        </option>
                                                                                        <option value="STC">
                                                                                            SERVICE TRUCK CRANE OPERATOR
                                                                                        </option>
                                                                                        <option value="SGP">
                                                                                            SIGNALPERSON
                                                                                        </option>
                                                                                        <option value="THF">
                                                                                            TELEHANDLER OPERATOR - FIXED
                                                                                        </option>
                                                                                        <option value="THR">
                                                                                            TELEHANDLER OPERATOR -
                                                                                            ROTATING
                                                                                        </option>
                                                                                        <option value="TWR">
                                                                                            TOWER CRANE OPERATOR
                                                                                        </option>
                                                                                    </Field>
                                                                                    <ErrorMessage
                                                                                        component={'div'}
                                                                                        name={'examtype'}
                                                                                        className="cco_form_message cco_form_message_error"
                                                                                    />
                                                                                </li>
                                                                                <li className="cco_form_fieldset_item cco_form_fieldset_item_large">
                                                                                    <label htmlFor="ccoisBoomTruck">
                                                                                        Quantity <Required />
                                                                                    </label>
                                                                                    <Field
                                                                                        as="select"
                                                                                        name="qty"
                                                                                        id="ccoquantity"
                                                                                    >
                                                                                        <option
                                                                                            value="default"
                                                                                            disabled={true}
                                                                                        >
                                                                                            Select an option
                                                                                        </option>
                                                                                        <option value="2">2</option>
                                                                                        <option value="5">5</option>
                                                                                        <option value="10">10</option>
                                                                                        <option value="20">20</option>
                                                                                    </Field>
                                                                                    <ErrorMessage
                                                                                        component={'div'}
                                                                                        name={'qty'}
                                                                                        className="cco_form_message cco_form_message_error"
                                                                                    />
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="cco_form_row">
                                                                    <div className="cco_form_col">
                                                                        {!isSubmitting && (
                                                                            <>
                                                                                {generatePDFMutation.data?.success && (
                                                                                    <div
                                                                                        className="cco_form_message cco_form_message_success"
                                                                                        style={{
                                                                                            fontSize: 16,
                                                                                            marginBottom: 20,
                                                                                        }}
                                                                                    >
                                                                                        <strong>
                                                                                            Generated PDF successfully.
                                                                                        </strong>
                                                                                    </div>
                                                                                )}

                                                                                {generatePDFMutation.error && (
                                                                                    <div
                                                                                        className="cco_form_message cco_form_message_error"
                                                                                        style={{
                                                                                            marginBottom: 20,
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            (
                                                                                                generatePDFMutation.error as Error
                                                                                            ).message
                                                                                        }
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        )}

                                                                        <div className="cco_form_row">
                                                                            <div className="cco_form_col">
                                                                                <ul className="cco_menu cco_menu_2 ">
                                                                                    <li
                                                                                        className={`cco_menu_item cco_menu_item_btn   ${
                                                                                            !termsAndConditions
                                                                                                ? 'cco_menu_item_btn_disabled'
                                                                                                : ''
                                                                                        }`}
                                                                                    >
                                                                                        {/* <a href={(!termsAndConditions) ? `javascript:void(0)` : `javascript:void(0)`} onClick={() => { isValidated ? event?.preventDefault : event?.preventDefault }}>{`Pay $${productData.ProductPrice} & Submit`}</a> */}

                                                                                        <button
                                                                                            className="cco_form_submit"
                                                                                            disabled={
                                                                                                !termsAndConditions
                                                                                            }
                                                                                            type={'submit'}
                                                                                            style={{
                                                                                                opacity:
                                                                                                    isValid &&
                                                                                                    dirty &&
                                                                                                    termsAndConditions
                                                                                                        ? '1'
                                                                                                        : '.5',
                                                                                                cursor:
                                                                                                    isValid &&
                                                                                                    dirty &&
                                                                                                    termsAndConditions
                                                                                                        ? 'pointer'
                                                                                                        : 'not-allowed',
                                                                                                fontSize: '16px',
                                                                                            }}
                                                                                        >
                                                                                            {isLoading
                                                                                                ? 'Generating PDF'
                                                                                                : 'Generate Score Sheet PDFs'}
                                                                                        </button>
                                                                                    </li>
                                                                                    <li
                                                                                        className={`cco_menu_item cco_menu_item_btn`}
                                                                                    >
                                                                                        <button
                                                                                            className="cco_form_submit"
                                                                                            onClick={() => {
                                                                                                setDocumentType('');
                                                                                                generatePDFMutation.reset();
                                                                                            }}
                                                                                            type={'button'}
                                                                                            style={{ fontSize: '16px' }}
                                                                                        >
                                                                                            {'Back'}
                                                                                        </button>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        </div>
                                                    </div>
                                                </form>
                                            )}
                                        </Formik>
                                    ) : (
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={initialValuesSGP}
                                            onSubmit={(values, { setSubmitting }) => {
                                                setisLoading(true);
                                                const GenerateObj: IGeneratePDF = {
                                                    firstname: data.First,
                                                    lastname: data.Last,
                                                    // accreditationnumber: 34523,
                                                    ccoid: data.CandidateId,
                                                };
                                                console.log(GenerateObj);
                                                console.log(values);
                                                generatePDFMutation
                                                    .mutateAsync({
                                                        body: GenerateObj,
                                                        type: documentType,
                                                    })
                                                    .then(async res => {
                                                        if (res.success && res.status === 200) {
                                                            const blob = await res.data.blob();
                                                            const todayDate = moment().format('MMDDYY');

                                                            // Create a download link
                                                            const downloadLink = document.createElement('a');
                                                            downloadLink.href = URL.createObjectURL(blob);
                                                            if (documentType === 'CRG') {
                                                                downloadLink.download = `CCO_CRG_${todayDate}.pdf`;
                                                            } else {
                                                                downloadLink.download = `CCO_${todayDate}.pdf`;
                                                            }
                                                            // Append the link to the body
                                                            document.body.appendChild(downloadLink);

                                                            // Trigger a click on the link to start the download
                                                            downloadLink.click();

                                                            // Remove the link from the body
                                                            document.body.removeChild(downloadLink);
                                                            setSubmitting(false);

                                                            setisLoading(false);

                                                            setTimeout(() => {});
                                                        }

                                                        // if (res?.data?.ApiCode === 200 && res.success === true) {

                                                        // }
                                                    })
                                                    .finally(() => {
                                                        setSubmitting(false);
                                                        setisLoading(false);
                                                    })
                                                    .catch(err => {
                                                        console.log(err);
                                                        setSubmitting(false);
                                                        setisLoading(false);
                                                    });
                                            }}
                                        >
                                            {({ isSubmitting, handleSubmit, handleReset }) => (
                                                <form
                                                    onReset={handleReset}
                                                    onSubmit={e => {
                                                        if (isSubmitting) {
                                                            e.preventDefault();
                                                        } else {
                                                            handleSubmit(e);
                                                        }
                                                    }}
                                                    className="cco_form"
                                                >
                                                    <div className="cco_row">
                                                        <div className="cco_col">
                                                            <>
                                                                <div className="cco_row cco_row_7">
                                                                    <div className="cco_col cco_col_6">
                                                                        <div className="cco_text cco_text_10">
                                                                            <div className="cco_text_inner">
                                                                            <h2 className="cco_cco_heading">
                                                                                    SIGNALPERSON EXAM AND CRG DOWNLOAD
                                                                                </h2>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/* <div className="cco_col cco_col_7">
                                            <div className="cco_btn cco_btn_3">
                                                <a href={'#'} onClick={handler}>Back to Test Site</a>
                                            </div>
                                        </div> */}
                                                                </div>

                                                                <div className="cco_row">
                                                                    <div className="cco_col_12">
                                                                        <h6>
                                                                            By downloading the Signalperson Practical
                                                                            Exam and Candidate Response Guide, you agree
                                                                            to the following terms and conditions:
                                                                        </h6>

                                                                        <ul style={{ listStyleType: 'disc' }}>
                                                                            <li>
                                                                            ▪️ You are a credentialed Practical Exam
                                                                                Proctor in the Signalperson program.
                                                                            </li>

                                                                            <li>
                                                                            ▪️ You are approved to administer
                                                                                Signalperson practical exams.
                                                                            </li>

                                                                            <li>
                                                                            ▪️ You understand that the Exam and
                                                                                Candidate Response Guide are protected
                                                                                by federal copyright law.
                                                                            </li>

                                                                            <li>
                                                                            ▪️  You will not share this link, Exam, or
                                                                                Candidate Response Guide with anyone.
                                                                            </li>

                                                                            <li>
                                                                            ▪️  You will keep the Exam and Candidate
                                                                                Response Guide in a secured location.
                                                                            </li>

                                                                            <li>
                                                                            ▪️ You will delete all copies of the Exam
                                                                                and Candidate Response Guide if required
                                                                                to do so by CCO.
                                                                            </li>

                                                                            <li>
                                                                            ▪️ You understand the consequences as
                                                                                outlined in the Practical Exam Proctor
                                                                                Handbook on the custody, care, and
                                                                                control of the Exam and Candidate
                                                                                Response Guide.
                                                                            </li>

                                                                            <li>
                                                                            ▪️ You are not acting to circumvent the
                                                                                sanction of another Practical Exam
                                                                                Proctor.
                                                                            </li>

                                                                            <li>
                                                                            ▪️ You agree to administer the exams
                                                                                according to CCO policies and
                                                                                procedures.
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                                <div className="cco_form_row">
                                                                    <div className="cco_form_col_1">
                                                                        <div className="cco_form">
                                                                            <div className="cco_form_row">
                                                                                <div className="cco_form_col">
                                                                                    <ul className="cco_menu cco_menu_8 ">
                                                                                        <li
                                                                                            className={
                                                                                                'cco_menu_item cco_menu_item_checkbox'
                                                                                            }
                                                                                        >
                                                                                            <label
                                                                                                className={
                                                                                                    'cco_form_checkbox cco_form_checkbox_0'
                                                                                                }
                                                                                            >
                                                                                                <input
                                                                                                    name="termsAndConditions"
                                                                                                    id="ccoTermsAndConditions"
                                                                                                    type="checkbox"
                                                                                                    className={
                                                                                                        'cco_form_checkbox_input'
                                                                                                    }
                                                                                                    value="termsAndConditions"
                                                                                                    onChange={
                                                                                                        handleTermsAndConditionsChange
                                                                                                    }
                                                                                                />
                                                                                                <span
                                                                                                    className={
                                                                                                        'cco_form_checkbox_checkmark'
                                                                                                    }
                                                                                                    style={{
                                                                                                        border: termsAndConditions
                                                                                                            ? '2px solid #c67b1d'
                                                                                                            : '1px solid #c67b1d',
                                                                                                    }}
                                                                                                ></span>
                                                                                                <p
                                                                                                    style={{
                                                                                                        fontSize:
                                                                                                            '12px',
                                                                                                    }}
                                                                                                >
                                                                                                    I acknowledge that I
                                                                                                    have read and
                                                                                                    understood the above
                                                                                                    policies and
                                                                                                    procedures in their
                                                                                                    entirety and agree
                                                                                                    to abide by them.
                                                                                                </p>
                                                                                            </label>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="cco_form_row">
                                                                    <div className="cco_form_col">
                                                                        {!isSubmitting && (
                                                                            <>
                                                                                {generatePDFMutation.data?.success && (
                                                                                    <div
                                                                                        className="cco_form_message cco_form_message_success"
                                                                                        style={{
                                                                                            fontSize: 16,
                                                                                            marginBottom: 20,
                                                                                        }}
                                                                                    >
                                                                                        <strong>
                                                                                            Generated PDF successfully.
                                                                                        </strong>
                                                                                    </div>
                                                                                )}

                                                                                {generatePDFMutation.error && (
                                                                                    <div
                                                                                        className="cco_form_message cco_form_message_error"
                                                                                        style={{
                                                                                            marginBottom: 20,
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            (
                                                                                                generatePDFMutation.error as Error
                                                                                            ).message
                                                                                        }
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        )}

                                                                        <div className="cco_form_row">
                                                                            <div className="cco_form_col">
                                                                                <ul className="cco_menu cco_menu_2 ">
                                                                                    <li
                                                                                        className={`cco_menu_item cco_menu_item_btn   ${
                                                                                            !termsAndConditions
                                                                                                ? 'cco_menu_item_btn_disabled'
                                                                                                : ''
                                                                                        }`}
                                                                                    >
                                                                                        {/* <a href={(!termsAndConditions) ? `javascript:void(0)` : `javascript:void(0)`} onClick={() => { isValidated ? event?.preventDefault : event?.preventDefault }}>{`Pay $${productData.ProductPrice} & Submit`}</a> */}
                                                                                        {documentType !== 'CRG' && (
                                                                                            <button
                                                                                                className="cco_form_submit"
                                                                                                disabled={
                                                                                                    !termsAndConditions
                                                                                                }
                                                                                                type={'submit'}
                                                                                                style={{
                                                                                                    opacity:
                                                                                                        termsAndConditions
                                                                                                            ? '1'
                                                                                                            : '.5',
                                                                                                    cursor: termsAndConditions
                                                                                                        ? 'pointer'
                                                                                                        : 'not-allowed',
                                                                                                    fontSize: '16px',
                                                                                                }}
                                                                                            >
                                                                                                {isLoading
                                                                                                    ? 'Generating PDF'
                                                                                                    : 'SGP Practical Exam PDF'}
                                                                                            </button>
                                                                                        )}
                                                                                    </li>
                                                                                    <li
                                                                                        className={`cco_menu_item cco_menu_item_btn   ${
                                                                                            !termsAndConditions
                                                                                                ? 'cco_menu_item_btn_disabled'
                                                                                                : ''
                                                                                        }`}
                                                                                    >
                                                                                        {/* <a href={(!termsAndConditions) ? `javascript:void(0)` : `javascript:void(0)`} onClick={() => { isValidated ? event?.preventDefault : event?.preventDefault }}>{`Pay $${productData.ProductPrice} & Submit`}</a> */}

                                                                                        {documentType === 'CRG' && (
                                                                                            <button
                                                                                                className="cco_form_submit"
                                                                                                disabled={
                                                                                                    !termsAndConditions
                                                                                                }
                                                                                                type={'submit'}
                                                                                                style={{
                                                                                                    opacity:
                                                                                                        termsAndConditions
                                                                                                            ? '1'
                                                                                                            : '.5',
                                                                                                    cursor: termsAndConditions
                                                                                                        ? 'pointer'
                                                                                                        : 'not-allowed',
                                                                                                    fontSize: '16px',
                                                                                                }}
                                                                                            >
                                                                                                {isLoading
                                                                                                    ? 'Generating PDF'
                                                                                                    : 'Generate Signalperson CRG PDF'}
                                                                                            </button>
                                                                                        )}
                                                                                    </li>
                                                                                    <li
                                                                                        className={`cco_menu_item cco_menu_item_btn`}
                                                                                    >
                                                                                        <button
                                                                                            className="cco_form_submit"
                                                                                            onClick={() => {
                                                                                                setDocumentType('');
                                                                                                generatePDFMutation.reset();
                                                                                            }}
                                                                                            type={'button'}
                                                                                            style={{ fontSize: '16px' }}
                                                                                        >
                                                                                            {'Back'}
                                                                                        </button>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        </div>
                                                    </div>
                                                </form>
                                            )}
                                        </Formik>
                                    )}
                                </>
                            )}
                        </>
                    </div>
                </div>
            </div>

            <BlockUI
                title="Generating PDF Please wait..."
                blocking={isLoading}
            />
        </article></div></div>
    );
};
