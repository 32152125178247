import React, { ReactNode, createContext, useContext } from 'react';
import { useContactAccessControl } from '../useContactAccessControl';
import { ContactAccessControl } from '../../types/dashboard';

export enum AccessControl {
  Normal = '',
  Watched = '',
  Delayed = 'Delayed',
  Suspended = 'Suspended',
  Locked = 'Locked',
}

type MessageObject = {
  red: string;
  orange: string;
  yellow: string;
  photo: string;
  [key: string]: string; 
};

type ProviderMessageObject = {
  red: string;
  orange: string;
  yellow: string;
  [key: string]: string;
};

const candidateBannerMessage: MessageObject = {
  red: 'Your account is locked. Should you have any questions, please contact Customer Service at candidate@nccco.org or 703-560-2391',
  orange: 'Your account is currently suspended. Some account and related data may still be visible. Should you have any questions, please contact Customer Service at candidate@nccco.org or 703-560-2391.',
  yellow: 'You are currently required to schedule your exam appointment date/time at least two business days in advance. Should you have any questions, please contact Customer Service at candidate@nccco.org or 703-560-2391.',
  photo: 'IMPORTANT: We need your photo! Please upload your headshot as soon as possible to ensure there are no delays in testing or receiving your certification card.',
};

const providerBannerMessage: ProviderMessageObject = {
  red: 'Your testing personnel account is locked. Should you have any questions, please contact Testing Personnel Support at testingpersonnel@nccco.org or 703-560-2391 ext. 812',
  orange: 'Your testing personnel account is currently suspended. Some account and related data may still be visible. Should you have any questions, please contact Testing Personnel Support at testingpersonnel@nccco.org or 703-560-2391 ext. 812.',
  yellow: 'Your testing personnel account is subject to the test administration advanced scheduling requirement. You may only create or be assigned to a test administration with a start date/time at least two business days into the future. Should you have any questions, please contact Testing Personnel Support at testingpersonnel@nccco.org or 703-560-2391 ext. 812.',
};

export const getBannerColor = (accessControl: string) => {
  return accessControl === AccessControl.Locked
    ? 'red'
    : accessControl === AccessControl.Suspended
    ? 'orange'
    : accessControl === AccessControl.Delayed
    ? 'yellow'
    : '';
};

interface IGlobalContext {
  CandidateAccessControl: string;
  ProviderAccessControl: string;
  CandidateBannerColor: string;
  ProviderBannerColor: string;
  ProviderBannerMessage: string;
  CandidateBannerMessage: string;
  PhotoBannerMessage: string;
  Color: string;
  FullName:string;
  CandidateId:string;
}

const GlobalContext = createContext<IGlobalContext | undefined>(undefined);

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobalContext must be used within a GlobalContextProvider');
  }
  return context;
};

interface GlobalContextProviderProps {
  children: ReactNode;
}


export const GlobalContextProvider = ({ children }:GlobalContextProviderProps) => {
  // const userData = getUserBasicData();
  const accessControlQuery = useContactAccessControl();

  const accessControlData = accessControlQuery.data?.data?.Data as ContactAccessControl;

  const CandidateAccessControl=accessControlData?.CandidateAccessControl;
  const ProviderAccessControl=accessControlData?.ProviderAccessControl;

  const candidateBannerColor = getBannerColor(CandidateAccessControl);
  const providerBannerColor = getBannerColor(ProviderAccessControl);

  const contextValues = {
    CandidateAccessControl: CandidateAccessControl,
    ProviderAccessControl: ProviderAccessControl,
    CandidateBannerColor: candidateBannerColor,
    ProviderBannerColor: providerBannerColor,
    ProviderBannerMessage: providerBannerMessage[providerBannerColor],
    CandidateBannerMessage: candidateBannerMessage[candidateBannerColor],
    PhotoBannerMessage: candidateBannerMessage.photo,
    Color: candidateBannerColor === 'yellow' || providerBannerColor === 'yellow' ? 'black' : '',
    CandidateId:accessControlData?.CandidateId || '',
    FullName:accessControlData?.FullName || ''
  };

  return (
    <GlobalContext.Provider value={contextValues}>
      {children}
    </GlobalContext.Provider>
  );
};
