const ES = {
    translation: {
      header: {
        roleManagement: 'Gestión de Roles',
        testingPersonel: 'Personal de Pruebas',
        accountManagement: 'Gestión de Cuenta',
        accountDetails: 'Detalles de la Cuenta',
        signout: 'Cerrar Sesión',
        loginRegister: 'Iniciar Sesión/Registrarse'
      },
      sidebar: {
        dashboardLink: 'Tablero',
        contactUsLink: 'Contáctenos',
        websiteLink: 'Sitio Web',
        newsLink: 'Noticias de CCO',
        resourcesLink: 'Recursos',
        storeLink: 'Tienda CCO'
      },
      dashboard: {
        profileCard: {
          pendingApproval: 'APROBACIÓN PENDIENTE',
          updatePhoto: 'Actualizar Foto',
          ccoId: 'ID de CCO',
          address: 'Dirección',
          phone: 'Teléfono',
          email: 'Correo Electrónico'
        },
        examHub: {
          heading: 'Centro de Exámenes myCCO',
          title: 'Dentro del centro myCCO puedes:',
          item1: 'Encontrar ubicaciones de pruebas escritas y prácticas',
          item2: 'Ver tus exámenes disponibles para programación',
          item3: 'Programar/Cambiar citas de exámenes',
          item4: 'Ver tus Resultados de Exámenes'
        },
        welcomeUser: 'Bienvenido al Tablero',
        login: 'Iniciar Sesión',
        hello: 'Hola {{name}},',
        applyNow: 'Aplica Ahora Para',
        learnLinkLabel: 'Conoce las opciones de pruebas escritas',
        candidateResources: 'Recursos para Candidatos',
        demoExamHeading: 'Prueba el examen de demostración de CCO',
        demoExamDescription: 'Para que estés preparado para el día del examen, familiarízate con el sistema de entrega de exámenes de CCO. Puedes familiarizarte con el diseño del examen, probar la calculadora en pantalla, navegar por las tablas de carga en pantalla y más.',
        demoExamButton: 'Lanzar Examen de Demostración',
        heading: '¿Nuevo en las pruebas OPT o EOT? ¡Haz una prueba de manejo!',
        paragraph: 'Familiarízate con el nuevo sistema de entrega de exámenes de CCO. CCO ha creado un examen de demostración gratuito que te permite navegar por el sistema de entrega de exámenes y probar la calculadora en pantalla, trabajar con archivos PDF de tablas de carga, revisar/marcar preguntas y más.',
        certificate: 'Mis Certificaciones',
        para2: 'No se encontraron certificados',
        schedule: 'Próximos Exámenes / Resultados Pendientes',
        para3: 'No se encontraron exámenes próximos',
        para4: '*Los resultados de los exámenes están sujetos a las determinaciones de validez de CCO y a otras correcciones apropiadas.',
        para5: 'No se encontraron exámenes anteriores',
        head2: 'Historial de Exámenes *',
        h2detail: 'últimos 12 meses',
        button1: 'Pruebas Escritas - EOT/OPT'
      },
      account: {
        incompleteProfile: 'Completa tu perfil ahora para acceder a tu ID de CCO y enviar una solicitud de examen.',
        pageHeading: 'Detalles de la Cuenta',
        personalInfo: '1. Información Personal',
        employeeInfo: '2. Información del Empleador',
        personalForm: {
          fullName: 'Nombre Completo',
          dob: 'Fecha de Nacimiento',
          email: 'Correo Electrónico',
          mobilePhone: 'Teléfono Móvil',
          street1: 'Calle 1',
          street2: 'Calle 2',
          country: 'País',
          noCountry: 'No se encontraron países. Inténtalo de nuevo más tarde.',
          city: 'Ciudad',
          zip: 'Código Postal'
        },
        employeeForm: {
          organization: 'Organización',
          phone: 'Teléfono',
          address1: 'Dirección 1',
          address2: 'Dirección 2',
          country: 'País',
          noCountry: 'No se encontraron países. Inténtalo de nuevo más tarde.',
          city: 'Ciudad',
          zip: 'Código Postal'
        },
        successMessage: 'Cuenta actualizada con éxito, haz clic en el botón Volver al Tablero para continuar.',
        saving: 'Guardando...',
        saveChangesButton: 'Guardar Cambios',
        returnDashboardButton: 'Volver al Tablero'
      },
      testingPersonnel: {
        pageHeading: 'Personal de Pruebas',
        ccoId: 'ID de CCO',
        noCredential: 'No hay credenciales disponibles',
        credentialJourneyButton: 'Comenzar el Trayecto de Credencial',
        availableCredentialButton: 'Ver Todas las Credenciales Disponibles'
      },
      myCredential: {
        breadcrumbsLink: 'Personal de Pruebas',
        status: 'Estado',
        expiryLabel: 'Capacitación Vencida',
        noExpiry: 'No Disponible',
        pendingAuditMessage: 'Contacta a CCO para programar tu auditoría.',
        pendingCertification: 'Debes completar tu certificación para obtener tu credencial.',
        renewButton: 'Renovar Ahora',
        workshopTitle: 'Talleres',
        startEnd: 'Fecha/Hora de Inicio',
        name: 'Nombre',
        launchLink: 'LanzarEnlace',
        location: 'Ubicación',
        noWorkshop: 'No hay aplicaciones de talleres disponibles',
        description: 'Descripción'
      }
    }
  };
  export default ES;
  