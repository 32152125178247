import type { IValuesPrac } from '../../containers/NewPracLocation/valuesTypes';

import InputMask from 'react-input-mask';

import { Field, ErrorMessage, useFormikContext } from 'formik';

export const TestSiteRepresentativeInformation = () => {
    const { values, handleChange, handleBlur, errors, setFieldTouched, setFieldValue, touched } = useFormikContext<IValuesPrac>();

    return (
        <div className="cco_form_row">
            <div className="cco_form_col">
                <h2 className="cco_form_heading">5. Test Site Representative Information</h2>

                <p className="cco_form_subheading">
                    The Test Site Representative is the Testing Facility Company Representative. This person authorizes the Test Site Coordinator to set up this
                    test site.
                </p>

                <div className="cco_form_wrap">
                    <ul className="cco_form_fieldset">
                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoFullNameTSR">Full Name <span style={{ color: '#db3a3a' }}>*</span></label>

                            <Field type="text" id="ccoFullNameTSR" name="fullName" />
                            <ErrorMessage component={'div'} name={'fullName'} className="cco_form_message cco_form_message_error" />
                        </li>

                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoPhoneTSR">Mobile Phone <span style={{ color: '#db3a3a' }}>*</span></label>

                            <InputMask
                                type={'tel'}
                                id={'ccoPhoneTSR'}
                                mask="+1 (999) 999-9999"
                                value={values.phoneNumber}
                                onChange={handleChange('phoneNumber')}
                                onBlur={handleBlur('phoneNumber')}
                            />

                            <ErrorMessage component={'div'} name={'phoneNumber'} className="cco_form_message cco_form_message_error" />
                        </li>

                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoEmailTSR">Email <span style={{ color: '#db3a3a' }}>*</span></label>

                            <Field type="email" id="ccoEmailTSR" name="email" />
                            <ErrorMessage name={'email'} component={'div'} className="cco_form_message cco_form_message_error" />
                        </li>

                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoEmailConfirmationTSR">Email Confirmation <span style={{ color: '#db3a3a' }}>*</span></label>

                            <Field type="email" id="ccoEmailConfirmationTSR" name="emailConfirmation" />
                            <ErrorMessage component={'div'} name={'emailConfirmation'} className="cco_form_message cco_form_message_error" />
                        </li>

                        <li className="cco_form_fieldset_item cco_form_fieldset_item_large">
                            <label htmlFor="ccoTCTSR">
                                Upload TSR Agreement <small>10MB max (pdf)</small><span style={{ color: '#db3a3a' }}>*</span>
                            </label>

                            <div className="cco_form_field cco_form_field_0">
                                <div className="cco_form_field_body">
                                    <label className="cco_form_field_label ccs_loader_relative_container" htmlFor="ccoTCTSR">
                                        {(values.termsAndConditions instanceof File && values.termsAndConditions.type.includes('image')) ||
                                        ('string' === typeof values.termsAndConditions &&
                                            values.termsAndConditions &&
                                            !values.termsAndConditions.includes('.pdf')) ? (
                                            <>
                                                <img
                                                    src={
                                                        values.termsAndConditions instanceof File
                                                            ? URL.createObjectURL(values.termsAndConditions)
                                                            : values.termsAndConditions
                                                    }
                                                    onLoad={e => {
                                                        const src = e.currentTarget.src;

                                                        if (src.startsWith('blob:')) URL.revokeObjectURL(src);
                                                    }}
                                                    alt="user profile picture"
                                                />
                                                <span className="cco_form_field_label_caption cco_form_field_label_caption_hover">Click to Upload</span>
                                            </>
                                        ) : (
                                            <>
                                                <span className="cco_form_field_label_icon cco_form_field_label_icon_file"></span>
                                                <span className="cco_form_field_label_caption">Click to Upload</span>
                                            </>
                                        )}
                                    </label>

                                    <Field
                                        type={'file'}
                                        id={'ccoTCTSR'}
                                        accept={'.pdf'}
                                        value={undefined}
                                        name={'termsAndConditions'}
                                        className={'cco_form_field_input'}
                                        onChange={(event: any) => {
                                            if (!event.currentTarget.files[0]) {
                                                return;
                                            }

                                            setFieldTouched('termsAndConditions', true);
                                            setFieldValue('termsAndConditions', event.currentTarget.files[0], true);
                                        }}
                                    />
                                </div>

                                <div className="cco_form_field_aside">
                                    {values.termsAndConditions && !errors.termsAndConditions && touched.termsAndConditions && (
                                        <div className="cco_form_message cco_form_message_has_icon cco_form_message_success">
                                            {values.termsAndConditions instanceof File ? values.termsAndConditions.name : null /* values.termsAndConditions */}
                                        </div>
                                    )}

                                    <ErrorMessage
                                        name={'termsAndConditions'}
                                        component={'div'}
                                        className="cco_form_message cco_form_message_has_icon cco_form_message_error"
                                    />
                                </div>
                            </div>

                            <div className="cco_form_btn cco_form_btn_2">
                                <a href="http://www.nccco.org/PRACtsra" target="_blank">
                                    Download Test Site Representative Agreement
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
