import type { IValues } from '../../../containers/NewLocation/valuesTypes';

import classNames from 'classnames';

import { useState, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { screenedValues } from '../../../tools/constants';
import { useCountries } from '../../../hooks/useCountries';
import { useStates } from '../../../hooks/useStates';

export const LocationInformation = ({
    handler,
    editable = true,
    single,
}: {
    handler?: (step: number) => void;
    editable?: boolean;
    single?: boolean;
}) => {
    const { values, errors, touched, submitCount } = useFormikContext<IValues>();

    const countriesQuery = useCountries('default' !== values.country);
    const statesQuery = useStates('default' !== values.country ? values.country : undefined);

    const statesData = useMemo(
        () => (statesQuery.data?.data.Data || []).find(state => state.ProvinceId === values.state),
        [statesQuery.data?.data.Data, values.state]
    );
    const countriesData = useMemo(
        () => (countriesQuery.data?.data.Data || []).find(country => country.CountryId === values.country),
        [countriesQuery.data?.data.Data, values.country]
    );

    const [isExpanded, setIsExpanded] = useState(single);

    const isFormCompleted = useMemo(
        () => screenedValues[0].every(field => !errors[field] && touched[field]),
        [errors, touched]
    );

    return (
        <div
            className={classNames('cco_collapse', {
                active: isExpanded,
            })}
        >
            <div className="cco_collapse_header">
                <div className="cco_text cco_text_3">
                    <div className="cco_text_inner">
                        <h3>1. Location Information</h3>

                        <div
                            className={classNames({
                                success: isFormCompleted,
                                error: !isFormCompleted,
                            })}
                        >
                            {isFormCompleted? 'Completed': submitCount < 1? 'In Progress': 'Please fill out all fields'}
                        </div>
                    </div>
                </div>

                <button
                    type="button"
                    className="cco_collapse_toggle"
                    onClick={() => setIsExpanded(prevState => !prevState)}
                >
                    <span className="cco_collapse_toggle_text">View</span>
                </button>
            </div>

            <div className="cco_collapse_body">
                <div className="cco_collapse_body_inner">
                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">Company Name</div>
                        <div className="cco_blurb_value">
                            <strong>{values.companyName || 'Not completed'}</strong>
                        </div>
                    </div>

                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">Street 1</div>
                        <div className="cco_blurb_value">
                            <strong>{values.street1 || 'Not completed'}</strong>
                        </div>
                    </div>

                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">Street 2</div>
                        <div className="cco_blurb_value">
                            <strong>{values.street2 || 'Not completed'}</strong>
                        </div>
                    </div>

                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">City</div>
                        <div className="cco_blurb_value">
                            <strong>{values.city || 'Not completed'}</strong>
                        </div>
                    </div>

                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">Zip</div>
                        <div className="cco_blurb_value">
                            <strong>{values.zip || 'Not completed'}</strong>
                        </div>
                    </div>

                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">State</div>
                        <div className="cco_blurb_value">
                            <strong>
                                {statesQuery.data ? statesData?.ProvinceName || 'Not completed' : 'Waiting...'}
                            </strong>
                        </div>
                    </div>

                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">Country</div>
                        <div className="cco_blurb_value">
                            <strong>
                                {countriesQuery.data ? countriesData?.CountryName || 'Not completed' : 'Waiting...'}
                            </strong>
                        </div>
                    </div>

                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">Secure facility ?</div>
                        <div className="cco_blurb_value">
                            <strong>
                                {'default' !== values.isASecureFacility
                                    ? 'yes' === values.isASecureFacility
                                        ? 'Yes'
                                        : 'No'
                                    : 'Not completed'}
                            </strong>
                        </div>
                    </div>

                    {'yes' === values.isASecureFacility && (
                        <div className="cco_blurb cco_blurb_1">
                            <div className="cco_blurb_label">Additional security</div>
                            <div className="cco_blurb_value">
                                <strong>{values.additionalSecurity ? 'Completed' : 'Not completed'}</strong>
                            </div>
                        </div>
                    )}

                    {editable && (
                        <div className="cco_btn cco_btn_2">
                            <a
                                href="#"
                                onClick={e => {
                                    handler?.(0);
                                    e.preventDefault();
                                }}
                            >
                                Edit
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
