const EN= {
      translation: {
        header:{
          roleManagement: 'Role Management',
          testingPersonel: 'Testing Personnel',
          accountManagement : 'Account management',
          accountDetails :'Account details',
          signout :'Sign out',
          loginRegister:"Login/Register"
        },
        sidebar:{
        dashboardLink : 'Dashboard',
        contactUsLink : 'Contact us',
        websiteLink :'Website',
        newsLink:"CCO News",
        resourcesLink:"Resources",
        storeLink:"CCO Store",

        },
        dashboard:{

          profileCard:{
          pendingApproval:"PENDING APPROVAL",
          updatePhoto:"Update Photo",
          ccoId : ' CCO ID',
          address:"Address",
          phone : 'Phone',
          email : 'Email',
          },
        examHub:{
          heading:"myCCO Exam Hub",
          title:"Inside the myCCO Hub, you can:",
          item1:"Find written and practical test locations",
          item2:"See your exams that are available for scheduling",
          item3:"Schedule/Change exam appointments",
          item4:"View your Exam Results",
        },
          welcomeUser : 'Welcome to the Dashboard' ,
        login : 'Log in',
        hello :'Hi {{name}},',
        applyNow : 'Apply Now For',
        learnLinkLabel : 'Learn about written testing options',
        candidateResources : 'Candidate Resources',
        demoExamHeading:"Test drive CCO's demo exam",
        demoExamDescription:"So that you are prepared for test day, familiarize yourself with CCO's exam delivery system. You can get comfortable with the exam layout, try out the on-screen calculator, navigate on-screen load charts, and more.",
        demoExamButton : 'Launch Demo Exam',
        heading :'New to OPT or EOT testing? Take a test drive!',
        paragraph : ' Familiarize yourself with CCO’s new exam delivery system. CCO has created a free demo exam allowing you to navigate then exam delivery system and try out the on-screen calculator, working with load chart PDF files, reviewing/flagging questions, and more.',
        certificate : 'My Certifications',
        para2 : 'No certificates found',
        schedule : 'Upcoming Exams / Pending Results',
        para3 : 'No upcoming exams found',
        para4 : '*Examination results are subject to CCO determinations of validity and other appropriate corrections.',
        para5 : 'No history exams found',
        head2 : '  Exam History *' ,
        h2detail : 'previous 12 months',
        button1: 'Written Testing - EOT/OPT'
      },
      account:{
        incompleteProfile:"Complete your profile now to access your CCO ID and submit an exam application.",
        pageHeading:"Account Details",
        personalInfo:"1. Personal Information",
        employeeInfo:"2. Employer Information",
        personalForm:{
          fullName:"Full Name",
          dob:"Date of Birth",
          email:"Email",
          mobilePhone:"Mobile Phone",
          street1:"Street 1",
          street2:"Street 2",
          country:"Country",
          noCountry:"No countries found. Please try again later.",
          city:"City",
          zip:"Zip",

        },
        employeeForm:{
          organization:"Organization",
          phone:"Phone",
          address1:"Address 1",
          address2:"Address 2",
          country:"Country",
          noCountry:"No countries found. Please try again later.",
          city:"City",
          zip:"Zip",
        },
        successMessage:"Successfully updated your account, click Return to Dashboard button to continue.",
        saving:"Saving...",
        saveChangesButton:"Save Changes",
        returnDashboardButton:"Return to Dashboard"
      },
      testingPersonnel:{
        pageHeading:"Testing Personnel",
        ccoId:"CCO ID",
        noCredential:"No Credentials available",
        credentialJourneyButton:"Start Credential Journey",
        availableCredentialButton:"View all Available Credentials",
      },
      myCredential:{
        breadcrumbsLink:"Testing Personnel",
        status:"Status",
        expiryLabel:"Training Due",
        noExpiry:"Not Available",
        pendingAuditMessage:"Contact CCO to schedule your audit.",
        pendingCertification:"You must complete your certification in order to earn your credential.",
        renewButton:"Renew Now",
        workshopTitle:"Workshops",
        startEnd:"Start Date/Time",
        name:"Name",
        launchLink:"LaunchLink",
        location:"Location",
        noWorkshop:"No workshop applications available",
        description:"Description",
        Resources :'Resources'
      },Credential:{
        testingPersonel:'Testing Personnel',
        JourneyStatus:'Journey Status',
        WrittenExamText1 :'Written Exam Required',
        WrittenExamText2 :'Click here to sign up',
        ExamStatus : 'Status',
        ExamTestDate : 'Test Date',
        CertificationText1 :'Certification Required',
        CertificationText2 :'Click here to sign up',
        CertExpirationDate :'Expiration Date',
        PreReqStatus:'Status',
        PreReqExpirationDate : 'Expiration Date',
        NotPurchased : 'Not Purchased',
        PreReqPara : 'There are no pre-requisites for',
         PreReqPara2: 'credential.'
      }
    }
    };
export default EN;