import { createNewSortInstance } from 'fast-sort';
import { Loader } from '../../components/Loader';
import { PRAC as PRACComponent } from '../../components/PRAC/PRAC';
import { usePRACTestSites } from '../../hooks/usePRACTestSites';
import { useCredentialValidate } from '../../hooks/useCredentialValidate';
import { useEffect, useState,  } from 'react';
import { customURLs, getToken, getUserBasicData } from '../../tools/utils';
import { useMsal } from '@azure/msal-react';
import { Link } from 'react-router-dom';
import { LocationRenewal } from '../../components/Renewals/LocationRenewal';
import { CadManagement } from '../CADManagement';

const naturalSort = createNewSortInstance({
    comparer: new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare,
});

export const TSCPRAC = () => {
    const data = getUserBasicData();
    const {instance}=useMsal();
    const activeAccount=instance.getActiveAccount()
    const validateQuery = useCredentialValidate('TSC-Practical');
    const validateData = validateQuery.data?.data;
    const { data: eotData, isLoading: eotISLoading, error: eotError } = usePRACTestSites();
    const token = getToken()
    const eotSites = naturalSort(eotData?.data.Data || []).asc(site => site.TestSiteNumber);
    const [showRenewComponent, setShowRenewComponent] = useState(false);
    
    const [locationId, setLocationId] = useState('');
    const [selectedPrac, setSelectedPrac] = useState('');
    const [showComponent, setShowComponent] = useState(false);
    const pending = eotSites.filter(test => 'PendingReview' === test.Status);
    const approved = eotSites.filter(test => 'Approved' === test.Status);
    const expired = eotSites.filter(test => 'Expired' === test.Status);
    console.log(approved);
    useEffect(() => {
        if (validateData) {

            if (!validateData.Data) {
                window.location.href = '/testing-personnel/';
            }
        }
        // Add any other code that needs to be executed when the page loads
    }, [validateData]);
    
    const handleLocationClick = (locationId: string, SiteNumber_CompanyName: string) => {
        setLocationId(locationId);
        setSelectedPrac(SiteNumber_CompanyName);
    };

    const handleLocationRenewClick = (locationId: string, SiteNumber_CompanyName: string) => {
        setLocationId(locationId);
        setSelectedPrac(SiteNumber_CompanyName);
        setShowRenewComponent(true);
    };

    const handleHideComponent = () => {
        setLocationId('');

    };


    function setShowTimer(show: boolean): void {
        throw new Error('Function not implemented.');
    }

    return (
        <div id="main-content">
        <div className="cco_content_area cco_content_area_0">
            <article className="react-container">
        <div className="cco_section cco_section_0">
            <div className="cco_row">
                <div className="cco_col">
                    <div className="cco_text cco_text_11">
                        <div className="cco_text_inner">
                            <strong>
                                <a href="/testing-personnel">Testing Personnel</a>
                            </strong>{' '}
                            {'>'} {showRenewComponent && locationId ? 'Practical Locations Renewal' : 'Practical Locations'}
                      

                        </div>
                    </div>
                </div>
            </div>

            <div className="cco_row cco_row_0">
                {/* {query.isLoading || userProfilePicture.isLoading ? (
                    <Loader />
                ) : query.error ? (
                    <p>{query.error.message}</p>
                ) : ( */}
                <>
                    <div className="cco_col cco_col_12">
                        <div className="cco_text cco_text_1">
                            <div className="cco_text_inner">
                                <h1>
                                    {showComponent && locationId ? selectedPrac : data?.FullName.toUpperCase()}
                                    <span className="divider" />
                                    <small>
                                        CCO ID <strong>{data?.CandidateId}</strong>
                                    </small>
                                </h1>
                            </div>
                        </div>
                    </div>
                </>
                {/* )} */}
            </div>


            {!showComponent && !locationId && (
                <>
                    <div className="cco_row cco_row_7">
                        <div className="cco_col cco_col_6">
                            <div className="cco_text cco_text_10">
                                <div className="cco_text_inner">
                                    <h2>My Practical Test Sites</h2>
                                </div>
                            </div>
                        </div>

                        <div className="cco_col cco_col_7">
                            <div className="cco_btn cco_btn_3">
                                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                <a
                                    // href={`${token && customURLs?.manageMyTestAdministrationsUrl?.button_url
                                    //     ? customURLs.manageMyTestAdministrationsUrl.button_url +
                                    //     '?id_token=' +
                                    //     token
                                    //     : '#'
                                    //     }`}
                                    target="_blank"
                                >
                                    {customURLs?.manageMyTestAdministrationsUrl?.button_text ||
                                        'Button Text'}
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="cco_row cco_row_3">
                        <div className="cco_col">

                            <ul className="cco_menu cco_menu_4">
                                <li className="cco_menu_item cco_menu_item_icon cco_menu_item_icon_plus">
                                <Link to="/tsc-prac/new-prac" state={{ eotId: null, single: false }}>
                                    Add new practical test site</Link>
                                </li>
                            </ul>
                        </div>
                    </div>


                   {eotISLoading ? (
                        <div className="cco_row cco_row_3">
                            <Loader />
                        </div>
                    ) : eotError ? (
                        <p>{eotError.message}</p>
                    ) : eotSites.length < 1 ? (
                        <div className="cco_row cco_row_3">
                            <p>No Practical Test Sites found</p>
                        </div>
                    ) : (
                        <>
                             {pending.length > 0 && (
                  <>
                            <div className="cco_row cco_row_7">
                                <div className="cco_col cco_col_6">
                                    <div className="cco_text cco_text_10">
                                        <div className="cco_text_inner">
                                            <h2>My Pending Review Practical Test Sites  </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="cco_row cco_row_3">
                                <div className="cco_col">

                                    <ul className="cco_accordion">
                                        {pending.map(site => (
                                            <PRACComponent key={site.LocationId} item={site} type="PendingReview" handler={handleLocationClick} />
                                        ))}
                                    </ul>

                                </div>
                            </div>
                        </>
                    )}
                        </>
                    )}
                    {approved.length > 0 && (
                        <>
                            <div className="cco_row cco_row_7">
                                <div className="cco_col cco_col_6">
                                    <div className="cco_text cco_text_10">
                                        <div className="cco_text_inner">
                                            <h2>My Approved Practical Test Sites</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="cco_row cco_row_3">
                                <div className="cco_col">

                                    <ul className="cco_accordion">
                                        {approved.map(site => (
                                            <PRACComponent key={site.LocationId} renewHandler={handleLocationRenewClick} handler={handleLocationClick} item={site} type="Approved" />
                                        ))}
                                    </ul>

                                </div>
                            </div>
                        </>
                    )}
                    {expired.length > 0 && (
                        <>
                            <div className="cco_row cco_row_7">
                                <div className="cco_col cco_col_6">
                                    <div className="cco_text cco_text_10">
                                        <div className="cco_text_inner">
                                            <h2>My Expired Practical Test Sites</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="cco_row cco_row_3">
                                <div className="cco_col">

                                    <ul className="cco_accordion">
                                        {expired.map(site => (
                                            <PRACComponent handler={handleLocationClick} renewHandler={handleLocationRenewClick} key={site.LocationId} item={site} type="Expired" />
                                        ))}
                                    </ul>

                                </div>
                            </div>
                        </>
                    )}




                </>
            )}
            {showComponent && locationId && (
                <div>
                    <CadManagement handler={handleHideComponent} LocationId={locationId} />
                </div>
            )}

            {showRenewComponent && locationId &&
                <LocationRenewal
                    locationId={locationId}
                    ProductName='Practical Test Site Annual Fee'
                    type='prac'
                    handlerShowTimer={setShowTimer}
                />
            }

        </div>
        </article>
        </div>
        </div>
    );
};
