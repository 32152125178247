import type { IValuesPrac, IOtherTSC } from '../../containers/NewPracLocation/valuesTypes';

import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { screenedValuesPrac } from '../../tools/constants';
import { OtherTSC } from '../../containers/NewPracLocation/SummaryComponents/OtherTSC';
import { Attestation } from '../../containers/NewPracLocation/SummaryComponents/Attestation';
import { ExamOffered } from '../../containers/NewPracLocation/SummaryComponents/ExamOffered';
import { LocationDoorSign } from '../../containers/NewPracLocation/SummaryComponents/LocationDoorSign';
import { PracticalSitePlan } from '../../containers/NewPracLocation/SummaryComponents/PracticalSitePlan';
import { LocationInformation } from '../../containers/NewPracLocation/SummaryComponents/LocationInformation';
import { TestSiteRepresentativeInformation } from '../../containers/NewPracLocation/SummaryComponents/TestSiteRepresentativeInformation';
import { BillingInformation } from './SummaryComponents/BillingInformation';
import { LocationProductData } from '../../types/product';

export const Summary = ({
    goTo,
    approved,
    selectedTSCs,
    singlePage = false,
    CreditCard,
    PaymentData
}: {
    goTo?: (step: number) => void;
    singlePage?: boolean;
    approved: boolean;
    selectedTSCs: IOtherTSC[];
    CreditCard: string,
    PaymentData: LocationProductData
}) => {
    const { setFieldTouched, values } = useFormikContext<IValuesPrac>();

    useEffect(() => {
        if (singlePage) {
            for (const [_, value] of Object.entries(screenedValuesPrac)) {
                value.forEach((item: keyof IValuesPrac) => setFieldTouched(item, true, true));
            }
        }
    }, [setFieldTouched, singlePage]);

    return (
        <div className="cco_form_row">
            <div className="cco_form_col">
                {!singlePage && <h2 className="cco_form_heading">Summary</h2>}

                <div className="cco_form_wrap">
                    <div className="cco_form_row cco_form_row_0">
                        <div className="cco_form_col cco_form_col_3">
                            <LocationInformation
                                handler={goTo}
                                single={singlePage}
                                editable={!singlePage /* values.prevState.canBeEdited */}
                            />

                            <LocationDoorSign
                                handler={goTo}
                                editable={!singlePage /* !singlePage && values.prevState.canBeEdited */}
                            />
                        </div>

                        <div className="cco_form_col cco_form_col_3">
                            <PracticalSitePlan
                                handler={goTo}
                                editable={!singlePage /* !singlePage && values.prevState.canBeEdited */}
                            />

                            <ExamOffered
                                handler={goTo}
                                editable={!singlePage /* !singlePage && values.prevState.canBeEdited */}
                            />


                        </div>

                        <div className="cco_form_col cco_form_col_3">
                            <TestSiteRepresentativeInformation
                                handler={goTo}
                                single={singlePage}
                                editable={!singlePage || values.prevState.canBeEdited}
                            />

                            <Attestation
                                handler={goTo}
                                editable={!singlePage /* !singlePage && values.prevState.canBeEdited */}
                            />

                            {approved && <OtherTSC handler={goTo} editable={true} selectedTSCs={selectedTSCs} />}
                        </div>
                        <div className="cco_form_col cco_form_col_3" hidden={singlePage}>
                            <BillingInformation
                                handler={goTo}
                                editable={!singlePage}
                                CreditCard={CreditCard}
                                Amount={PaymentData.ProductPrice}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
