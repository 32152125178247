import type { IValuesPrac } from '../../../containers/NewPracLocation/valuesTypes';

import classNames from 'classnames';

import { useState, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { screenedValuesPrac } from '../../../tools/constants';
import { usePracticalExamTypes } from '../../../hooks/usePracticalExamTypes';


export const ExamOffered = ({ handler, editable = true }: { handler?: (step: number) => void; editable?: boolean }) => {
    const { values, errors, touched, submitCount } = useFormikContext<IValuesPrac>();

    const [isExpanded, setIsExpanded] = useState(false);

    const isFormCompleted = useMemo(() => screenedValuesPrac[2].every(field => !errors[field]), [errors, touched]);
    const examTypesQuery=usePracticalExamTypes();
    const examTypesData = examTypesQuery.data?.data.Data;
  
    function getName(id:string){
            return examTypesData?.filter(item => item.Id===id)[0].Name;
    }

    return (
        <div
            className={classNames('cco_collapse', {
                active: isExpanded
            })}>
            <div className="cco_collapse_header">
                <div className="cco_text cco_text_3">
                    <div className="cco_text_inner">
                        <h3>4. Practical Exam Offered</h3>

                        <div
                            className={classNames({
                                success: isFormCompleted,
                                error: !isFormCompleted
                            })}>
                            {isFormCompleted
                             ? 'Completed' 
                             : submitCount < 1 
                             ? 'In Progress' 
                             : 'Please fill out all fields'}
                        </div>
                    </div>
                </div>

                <button type="button" className="cco_collapse_toggle" onClick={() => setIsExpanded(prevState => !prevState)}>
                    <span className="cco_collapse_toggle_text">View</span>
                </button>
            </div>

            <div className="cco_collapse_body">
                <div className="cco_collapse_body_inner">
                    {(!values.PracSiteTypes.length || !values.PracSiteTypes) && <div className="cco_blurb cco_blurb_error cco_blurb_3">Nothing selected</div>}

                    {values.PracSiteTypes.length > 0 &&
                        (values.PracSiteTypes).map((choice, index) => (
                            
                            <div
                                className={classNames('cco_blurb cco_blurb_3', {
                                    cco_blurb_success: values.PracSiteTypes.includes(choice),
                                   
                                })}
                                key={index}>
                                {getName(choice)}
                            </div>
                        ))}

                        <div style={{borderTop:"1px solid #e4e4e2",marginTop:"10px",paddingTop:"10px"}}>
                           {<div className={!values.PracticalAttestation.includes('PracticalAttestation')?"cco_blurb cco_blurb_error cco_blurb_3":classNames('cco_blurb cco_blurb_3', {
                                    cco_blurb_success: values.PracticalAttestation.includes('PracticalAttestation')
                                })}>Is Practical Attestation</div>}     
                        </div>
                        

                    {editable && (
                        <div className="cco_btn cco_btn_2">
                            <a
                                href="#"
                                onClick={e => {
                                    handler?.(2);
                                    e.preventDefault();
                                }}>
                                Edit
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
