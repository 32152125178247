import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import EN from './resources/en'
import ES from './resources/es'
const storedLanguage = localStorage.getItem('i18nextLng');




i18n.use(initReactI18next)
  .init({
    resources:{
      EN,
      ES
    },
    lng:'EN',
    fallbackLng: 'EN', 
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;