import { Loader } from '../../components/Loader';
import { usePracticalExamTypes } from '../../hooks/usePracticalExamTypes';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import { IValuesPrac } from './valuesTypes';
// import { ChangeEvent } from 'react';

export const ExamOffered = () => {
    const examTypesQuery = usePracticalExamTypes();
    const examTypesData = examTypesQuery.data?.data.Data;
    const { values} = useFormikContext<IValuesPrac>();
    // function handleTermsAndConditionsChange(event: ChangeEvent<HTMLInputElement>): void {
    //     console.log(event)
    //     throw new Error('Function not implemented.');
        
    // }
    const language= "I understand and acknowledge that CCO has established unique and specific criteria for each practical exam type and the related equipment to be used for testing, which may include but is not limited to, the testing area size, testing area surface, levelness of the ground, proximity to obstructions and hazards and that the equipment to be used for testing is compliant with all applicable industry and manufacturer standard(s). I attest that I have read and familiarized myself with CCO's criteria for Practical Test Sites, as set forth in the Test Administration Handbook - Practical and agree to prepare this Test Site and related equipment in accordance with the stated requirements. I furthermore attest that the testing course is free of all training aides and training/reference materials."

    return (
        <div className="cco_form_row">
            <div className="cco_form_col">
                <h2 className="cco_form_heading">4. Practical Exams Offered</h2>

                <div className="cco_form_wrap">
                {examTypesQuery.isLoading ? '':
                    <div className="cco_text cco_text_8">
                        <div className="cco_text_inner">Please select the CCO practical exams you plan to offer at this test site.</div>
                    </div>
                }
                    <ul className="cco_form_fieldset">
                        {examTypesQuery.isLoading ? (<Loader />) : examTypesQuery.error ? (<p style={{color:"red"}} >{examTypesQuery.error.message}</p>) 
                        :
                        (
                            examTypesData?.map(type => {
                                return (
                                    <li className="cco_form_fieldset_item cco_form_fieldset_item_small" key={type.Id}>
                                        <label className="cco_form_checkbox">
                                            <Field id="ccoParking" type="checkbox" name="PracSiteTypes" value={type.Id} className="cco_form_checkbox_input" />
                                            <span className="cco_form_checkbox_checkmark"></span>
                                            {type.Name}
                                        </label>
                                    </li>
                                );
                            })
                        )}
                    </ul>

                    <ErrorMessage component={'div'} name={'PracSiteTypes'} className="cco_form_message cco_form_message_error" />
                </div>
                {/* checkbox */}
                {/* <div className="cco_form_row">
                                            <div className="cco_form_col"> */}
                                              
                                                    <ul className="cco_menu cco_menu_4" style={{ border: '0px' }}>
                                                        <li className={'cco_menu_item cco_menu_item_checkbox'}>
                                                            <label className={'cco_form_checkbox cco_form_checkbox_0'} style={{ paddingLeft: "60px" }}>
                                                               
                                                                      <Field id="ccoPracticalAttestation" type="checkbox" name="PracticalAttestation" value='PracticalAttestation' className="cco_form_checkbox_input" />
                                                                      {/* style={{ border: 1==1 ? "2px solid #c67b1d" : '1px solid #c67b1d' }} */}
                                                                <span className={'cco_form_checkbox_checkmark'} style={{ border: values.PracticalAttestation.includes('PracticalAttestation') ? "2px solid #c67b1d" : '1px solid #c67b1d' }}></span>
                                                                <p style={{ fontSize: "14px" }}>
                                                             {language}
                                                                </p>
                                                            </label>
                                                        </li>
                                                    </ul>
                    <ErrorMessage component={'div'} name={'PracticalAttestation'} className="cco_form_message cco_form_message_error" />


                                                {/* </div>
                                                </div> */}
                </div>
        </div>
    );
};
