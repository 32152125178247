import { useMyCerts } from '../../hooks/useMyCerts';
import { NullBadgeUrl } from '../../tools/utils';
import { MyCertifications, RootObject } from '../../types/mycertifications';
import moment from 'moment';
import { Loader } from '../Loader';
import useIsMobile from '../../hooks/Helper/useIsMobile';
import { isTablet } from 'react-device-detect';

interface IMyCertificationCardProps {
    ProviderAccessControl?: string | null;
    
}

export const MyCertificationCard = ({ ProviderAccessControl }: IMyCertificationCardProps) => {

    const query = useMyCerts();
    const isMobile =useIsMobile();
    const isIpad =(window.innerWidth >= 768 && window.innerWidth <= 1366);
    // const myCertData = query.data?.data;
    const myCertData = (query.data?.data || []) as unknown as RootObject['data']['Data'];
    const handleBorder = (CertExpirationDate: string) => {
        if (CertExpirationDate !== '0001-01-01T00:00:00') {
            const expirationDate = moment(CertExpirationDate);
            const currentDate = moment();
            const sixtyDaysBefore = expirationDate.clone().subtract(60, 'days');
            const isTodayExpiryDate = currentDate.isSame(expirationDate, 'day');
            const isExpiryWithinSixtyDays = currentDate.isBefore(expirationDate) && currentDate.isSameOrAfter(sixtyDaysBefore, 'day');

            return isTodayExpiryDate || isExpiryWithinSixtyDays;
        }
        return false;
    }

    return (
        <>
            <div className="cco_row cco_row_7">
                <div className="cco_col cco_col_6">
                    <div className="cco_text cco_text_10">
                        <div className="cco_text_inner">
                            <h2>My Certifications</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cco_row cco_row_3">
                <div className="cco_col">
                    {query.isLoading ? (
                        <Loader />
                    ) : query.error ? (
                        <p>{query.error.message}</p>
                    ) : (myCertData.length > 0 ? (
                            <div className="cco_group cco_group_myCredential">
                                {myCertData.map((certification: MyCertifications, index: number) => (
                                    <div className="cco_group_item tpCards" key={certification.Id + index}>
                                
                                        <a
                                            href={
                                                ProviderAccessControl !== 'Suspended'
                                                    ? '#'
                                                    : ''
                                            }
                                            onClick={(e) => {
                                                if (ProviderAccessControl === 'Suspended') {
                                                    e.preventDefault();
                                                }
                                            }}
                                        >
                                            
                                            <div className="cco_card cco_card_myCredential" style={{ border: handleBorder(certification.CertExpirationDate) ? '4px red solid' : '' }}>
                                                <div className="cco_card_body">
                                                    <div className="cco_card_aside">
                                                        <div className="cco_img">
                                                            <span className="cco_img_wrap">
                                                                <img
                                                                    style={{
                                                                        border:
                                                                            (certification.StatusReason === 'Approved' || certification.StatusReason === 'PendingRecert' || certification.StatusReason === 'Active') &&
                                                                                certification.Logo !== null
                                                                                ? ''
                                                                                : '1px solid rgb(0 0 0 / 5%)',
                                                                        borderRadius:
                                                                            (certification.StatusReason === 'Approved' || certification.StatusReason === 'PendingRecert' || certification.StatusReason === 'Active') &&
                                                                                certification.Logo !== null
                                                                                ? ''
                                                                                : '23px',
                                                                         
                                                                    }}
                                                                    alt={certification?.MyCCOLabel}
                                                                    src={
                                                                        (certification.StatusReason === 'Approved' || certification.StatusReason === 'PendingRecert' || certification.StatusReason === 'Active') &&
                                                                            certification.Logo !== null
                                                                            ? certification.Logo
                                                                            : NullBadgeUrl
                                                                    }
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="cco_card_main"
                                                        style={{
                                                            width: '100%',
                                                            padding: '10px',
                                                        }}
                                                    >
                                                        <div className="cco_text cco_text_myCredential">
                                                            <div className="cco_text_inner">
                                                            <h3
  style={{
    fontSize: 
      (certification?.MyCCOLabel?.split(' ').length === 4 && isMobile)
        ? '9.5px' // Decrease size for mobile if there are more than 26 words
        :(certification?.MyCCOLabel?.split(' ').length === 7 && isMobile)
        ? '7.5px'
        :(certification?.MyCCOLabel?.split(' ').length === 4 && isIpad )
        ? '9.5px' // Decrease size for mobile if there are more than 26 words
        :(certification?.MyCCOLabel?.split(' ').length === 7 && isIpad)
        ? '7.5px'
        : certification?.MyCCOLabel?.split(' ').length > 4
        ? '11.5px' // Decrease size if there are more than 26 words (not mobile)
        : '12px', // Default size
  }
 }
>
  {certification?.MyCCOLabel}

  
</h3>
                                                        <div>
                                                                    Status:{' '}
                                                                    {certification.StatusReason}
                                                                </div>
                                                                <div>
                                                                  <span>
                                                                  Exp Date:{' '}
                                                                    {certification?.CertExpirationDate?.split('T')[0] === '0001-01-01'
                                                                        ? 'Not Available'
                                                                        : certification?.CertExpirationDate?.split('T')[0]}
                                                             
                                                                  </span>
                                                                  {/* <span style={{float:'right'}}>
                                                                  <img width="40" height="40" src="https://img.icons8.com/ios/50/certificate--v1.png" alt="certificate--v1"/>
                                                                  </span> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </a>

                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>No Certifications available</p>
                        )
                    )}
                </div>
            </div>
        </>
    );
};
