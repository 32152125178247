import type { IValues } from './valuesTypes';
import image1 from "../../assets/images/TFP_Sample.jpg"
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { ChangeEvent, useState } from 'react';

export const InternetSpeed = () => {
    const { values, errors, setFieldTouched, setFieldValue, touched } = useFormikContext<IValues>();
    const [fileError, setFileError] = useState<string | null>(null);
    const [sitePlanError, setSitePlanError] = useState<string | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: string, setError: React.Dispatch<React.SetStateAction<string | null>>) => {
        const file = event.currentTarget.files ? event.currentTarget.files[0] : null;

        if (!file) return;

        if (file.name.toLowerCase().endsWith('.jfif')) {
            setError('This file type is not supported');
            setFieldValue(fieldName, null); // Reset field value
            setFieldTouched(fieldName, false); // Prevent validation error
        } else {
            setError(null); // Clear error if file is valid
            setFieldTouched(fieldName, true);
            setFieldValue(fieldName, file);
        }
    };
    return (
        <div className="cco_form_row">
            {/* <div className="cco_form_col cco_form_col_0">
                <h2 className="cco_form_heading">4. Test Site Capacity</h2>

                <div className="cco_form_wrap">
                    <ul className="cco_form_fieldset">
                    
                        <li className="cco_form_fieldset_item">
                        <h2 className="cco_form_heading">A. EOT Test Site Internet Requirements (Minimum)</h2>
      <p className='cco_form_note'>
        Testing Facilities must be able to meet the following technical requirements to be approved for EOT testing:
      </p>
      <ul className='cco_form_note' style={{marginLeft:'20px'}} >
        <li>
          ○ Non-Cellular Internet Source (e.g., broadband, cable)
          <ul  style={{marginLeft:'20px'}} >
            <li>▪️ Recommended download speed of 12 Mbps per device (minimum 2 Mbps)</li>
            <li>▪️ Recommended upload speed of 3 Mbps per device (minimum 2 Mbps)</li>
          </ul>
     <strong><p style={{marginLeft:'10px', fontSize:'15px', marginBottom:'10px'}}><em>Note: Cellular/mobile hot spots or cellular MiFi devices are not supported.</em></p></strong> 
        </li>
        <li>
          ○ Dedicated internet network
          <ul style={{marginLeft:'20px', marginBottom:'10px'}}>
            <li>▪️  Recommended: hard-wired internet network (Ethernet)</li>
            <li>▪️ Minimum: Wireless Internet Network (WiFi)</li>
          </ul>
        </li>
        <li>
        ○ Private and Password Protected
        </li>
        <li>
        ○ Firewalls Disabled
        </li>
        <strong><p style={{marginLeft:'10px', fontSize:'15px', marginBottom:'10px'}}><em>Note: Public or shared networks are not supported (e.g., hotel public WiFi)..</em></p></strong> 
      </ul>
     

      <p className='cco_form_note'>
        Review the full technical requirements as found in the Test Administration Handbook – EOT Testing, and access online tools to measure the internet speed – <a href="https://www.example.com">click here</a>.
      </p>
      
      <p className="cco_form_note">
                                Don’t know your internet speed? Contact testing facility IT support or run a speed test. This link will allow you to run a live
                                speed test:{' '}
                                <a href="https://www.speedtest.net/" target="_blank" rel="noopener noreferrer">
                                    https://www.speedtest.net/
                                </a>
                                . Make sure you perform the test in the testing room you plan to use.<br/></p>

                             <h4 className="cco_form_heading">B.	Test Site Capacity</h4>
                            <label htmlFor="ccoTestingStations">
                            Based on all factors, including the available internet speed, internet network and any spacing or equipment limitations, how many candidate testing stations is facility able to support?
                            </label>

                            <Field type="text" id="ccoTestingStations" name="numberOfCandidateTestingStations" />

                            <ErrorMessage component={'div'} name={'numberOfCandidateTestingStations'} className="cco_form_message cco_form_message_error" />

                        </li>
                        <li className="cco_form_fieldset_item">
                        <h4 className="cco_form_heading">C.	Attestation Statement</h4>
                            <label htmlFor="ccoInternetSpeed">
                            I have confirmed that this testing facility meets the EOT Test Site Internet Requirements, and that the internet download/upload speeds will support the number of candidate testing stations entered above.
                            </label>

                            <Field as="select" name="internetSpeed" id="ccoInternetSpeed">
                                <option value="default" disabled={true}>
                                    Select an option
                                </option>

                                <option value="yes">Yes</option>
                                <option value="no">No - Yes is required to submit application</option>
                            </Field>

                            <ErrorMessage component={'div'} name={'internetSpeed'} className="cco_form_message cco_form_message_error" />

                        </li>
                    </ul>
                </div>
            </div> */}
            <div className="cco_form_col cco_form_col_2">
                <h2 className="cco_form_heading">4. Testing Room Layout</h2>

                <div className="cco_form_wrap">
                    <label htmlFor="ccoSitePlan">
                        Upload Testing Room Layout <small>10MB max – only .jpeg/.jpg, .png, or .pdf file types supported</small>
                    </label>

                    <div className="cco_form_field cco_form_field_0">
                        <div className="cco_form_field_body">
                            <label className="cco_form_field_label ccs_loader_relative_container" htmlFor="ccoSitePlan">
                                {(values.sitePlan instanceof File && values.sitePlan.type.includes('image')) ||
                                ('string' === typeof values.sitePlan && values.sitePlan && !values.sitePlan.includes('.pdf')) ? (
                                    <>
                                        <img
                                            src={values.sitePlan instanceof File ? URL.createObjectURL(values.sitePlan) : values.sitePlan}
                                            onLoad={e => {
                                                const src = e.currentTarget.src;

                                                if (src.startsWith('blob:')) URL.revokeObjectURL(src);
                                            }}
                                            alt=""
                                        />
                                        <span className="cco_form_field_label_caption cco_form_field_label_caption_hover">Click Here to Upload</span>
                                    </>
                                ) : (
                                    <>
                                        <span className="cco_form_field_label_icon cco_form_field_label_icon_file"></span>
                                        <span className="cco_form_field_label_caption">Click Here to Upload</span>
                                    </>
                                )}
                            </label>

                            <Field
                                type={'file'}
                                value={undefined}
                                name={'sitePlan'}
                                id={'ccoSitePlan'}
                                className={'cco_form_field_input'}
                                accept={'.pdf, image/png, image/jpeg'}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleFileChange(e, 'sitePlan', setSitePlanError)}
                                />
                            </div>
    
                            <div className="cco_form_field_aside">
                            {sitePlanError && (
                                    <div className="cco_form_message cco_form_message_has_icon cco_form_message_error">
                                        {sitePlanError}
                                    </div>
                                )}
                            {values.sitePlan && !errors.sitePlan && touched.sitePlan && (
                                <div className="cco_form_message cco_form_message_has_icon cco_form_message_success">
                                    {values.sitePlan instanceof File ? values.sitePlan.name : null /* values.sitePlan */}
                                </div>
                            )}

                            <ErrorMessage name={'sitePlan'} component={'div'} className="cco_form_message cco_form_message_has_icon cco_form_message_error" />
                        </div>
                    </div>


                    <div className="cco_form_btn cco_form_btn_2">
                        <a href={'https://www.nccco.org/EOTlayout'} target="_blank">
                            Download Testing Room Layout Form
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
